import { createIcon } from "../create-icon"

export const UploadIcon = createIcon({
  title: "UploadIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5496 2.23966C8.40769 2.08684 8.20856 2 8 2C7.79145 2 7.59232 2.08684 7.45041 2.23966L4.20041 5.73966C3.91856 6.04319 3.93613 6.51774 4.23966 6.7996C4.5432 7.08145 5.01775 7.06387 5.2996 6.76034L7.25 4.6599V10.25C7.25 10.6642 7.58579 11 8 11C8.41422 11 8.75 10.6642 8.75 10.25V4.6599L10.7004 6.76034C10.9823 7.06387 11.4568 7.08145 11.7603 6.7996C12.0639 6.51774 12.0815 6.04319 11.7996 5.73966L8.5496 2.23966ZM3.5 10.75C3.5 10.3358 3.16421 10 2.75 10C2.33579 10 2 10.3358 2 10.75V13.25C2 13.6642 2.33579 14 2.75 14H13.2491C13.6633 14 13.9991 13.6642 13.9991 13.25V10.75C13.9991 10.3358 13.6633 10 13.2491 10C12.8349 10 12.4991 10.3358 12.4991 10.75V12.5H3.5V10.75Z"
      fill="currentColor"
    />
  ),
})

UploadIcon.displayName = "UploadIcon"
