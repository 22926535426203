import { createIcon } from "../create-icon"

export const FilePdfIcon = createIcon({
  title: "FilePdfIcon",
  viewBox: "0 0 16 20",
  path: (
    <>
      <mask
        id="mask0_997_1100"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="16"
      >
        <path
          d="M2.82759 2H10.5075C10.6166 2 10.7213 2.04502 10.7988 2.12526L13.8775 5.31335C13.9559 5.39456 14 5.50515 14 5.62052V17.1351C14 17.6128 13.6295 18 13.1724 18H2.82759C2.37052 18 2 17.6128 2 17.1351V2.86486C2 2.38721 2.37052 2 2.82759 2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_997_1100)">
        <path
          d="M2.82759 2H10.5075C10.6166 2 10.7213 2.04502 10.7988 2.12526L13.8775 5.31335C13.9559 5.39456 14 5.50515 14 5.62052V17.1351C14 17.6128 13.6295 18 13.1724 18H2.82759C2.37052 18 2 17.6128 2 17.1351V2.86486C2 2.38721 2.37052 2 2.82759 2Z"
          fill="#FF4747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6777 2H13.9881V5.24324H11.5053C11.0483 5.24324 10.6777 4.88023 10.6777 4.43243V2Z"
          fill="#FFA3A3"
        />
      </g>
      <path
        d="M10.8328 10.7364C10.3651 10.7449 9.89963 10.8024 9.44392 10.9079C8.88233 10.3484 8.41651 9.70036 8.06509 8.98977C8.44685 7.65595 8.46392 6.74656 8.17915 6.31592C8.11562 6.22268 8.03102 6.14572 7.93221 6.09126C7.8334 6.0368 7.72315 6.00637 7.6104 6.00245C7.4698 5.99071 7.32892 6.0214 7.20595 6.09056C7.08299 6.15972 6.98359 6.26417 6.9206 6.39041C6.52254 7.10271 7.09751 8.51645 7.3652 9.08831C7.06388 10.1095 6.67122 11.1016 6.192 12.0524C4.06285 13.0347 4 13.6314 4 13.8463C4.00776 14.1117 4.15286 14.3491 4.37555 14.4608C4.47259 14.5272 4.5887 14.5599 4.70609 14.554C5.27485 14.554 5.90103 13.8836 6.59083 12.5738C7.43659 12.1874 8.31727 11.8832 9.22122 11.6644C9.67126 12.0811 10.2493 12.334 10.8615 12.3829C11.2371 12.3829 11.9998 12.3829 11.9998 11.5659C12.006 11.2888 11.8524 10.7604 10.8328 10.7356V10.7364ZM4.74567 13.8711H4.67739C4.86438 13.5623 5.14294 13.3202 5.47426 13.1759C5.31842 13.4844 5.06115 13.7299 4.74567 13.8711ZM7.46762 6.74579C7.49686 6.73462 7.52918 6.73462 7.55841 6.74579H7.62669C7.74405 7.14734 7.74405 7.57408 7.62669 7.97563C7.44953 7.59149 7.39403 7.16236 7.46762 6.74579ZM8.60669 11.3804C8.09458 11.51 7.59022 11.6698 7.09751 11.8607V11.811H7.05173C7.2977 11.2803 7.52039 10.7387 7.71903 10.1886V10.1389C7.97508 10.5603 8.26994 10.9513 8.60048 11.3067H8.53841L8.60669 11.3812V11.3804ZM10.884 11.6947C10.6519 11.685 10.4237 11.6307 10.2121 11.5348C10.3991 11.4952 10.5907 11.4782 10.7816 11.4859C11.22 11.4859 11.3116 11.6023 11.3116 11.676C11.1709 11.7176 11.0223 11.724 10.8786 11.6947H10.884Z"
        fill="white"
      />
    </>
  ),
})

FilePdfIcon.displayName = "FilePdfIcon"
